import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { sessionKey } from "./Constants/appConstants";
import RolesManagement from "./pages/RolesManagement/RolesManagement";
import { BrowserStorage } from "./utils/browserStorage";

function App() {
  const authDetails = BrowserStorage.getSessionItem(sessionKey);

  return (
    <Router>
      <Routes>
        {authDetails && (
          <>
          <Route path="/account-settings" element={<RolesManagement />} />
          <Route path="*" element={<div></div>} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
