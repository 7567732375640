import React, { useState } from "react";
import styles from "./SidePanel.module.scss";

const SidePanel: React.FC = () => {
  const [formValues, setFormValues] = useState({
    applicationName: "",
    description: "",
    primaryContact: "",
    businessOwner: "",
    adminGroup: "",
    azureADEnrolledGroup: "",
    clientId: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCancel = () => {
    setFormValues({
      applicationName: "",
      description: "",
      primaryContact: "",
      businessOwner: "",
      adminGroup: "",
      azureADEnrolledGroup: "",
      clientId: "",
    });
  };

  return (
    <div className={styles.panel}>
      {/* <div className={styles.header}>
        <h2>Add/Edit Application</h2>
      </div> */}
      <form className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="applicationName">Application Name</label>
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="applicationName"
              value={formValues.applicationName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="description">Description</label>
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="description"
              value={formValues.description}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="primary contact">Primary Contact</label>
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="primaryContact"
              value={formValues.primaryContact}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="business owner">Business Owner</label>
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="businessOwner"
              value={formValues.businessOwner}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="admin group">Admin Group</label>
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="adminGroup"
              value={formValues.adminGroup}
              onChange={handleInputChange}
            />
            <button type="button" className={styles.ellipsisButton}>
              ...
            </button>
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="azure ad">Azure AD Enrolled Group</label>
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="azureADEnrolledGroup"
              value={formValues.azureADEnrolledGroup}
              onChange={handleInputChange}
            />
            <button type="button" className={styles.ellipsisButton}>
              ...
            </button>
          </div>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="clientid">ClientID</label>
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="clientId"
              value={formValues.clientId}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <button type="submit" className={styles.submitButton}>
            Submit
          </button>
          <button
            type="button"
            className={styles.cancelButton}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default SidePanel;
