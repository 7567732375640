export const BrowserStorage = {
  setItem: (key: string, value: string) => {
    localStorage.setItem(key, value);
  },
  getItem: (key: string) => {
    return localStorage.getItem(key);
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
  clear: (key: string) => {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  },
  setSessionItem: (key: string, value: string) => {
    sessionStorage.setItem(key, value);
  },
  getSessionItem: (key: string) => {
    return sessionStorage.getItem(key);
  },
};
