import { LoadingSpinner, Panel } from "cubee2e-cube-global-components";
import style from "./RolesManagement.module.scss";
import React, { Suspense, lazy, useState } from "react";
import SidePanel from "../../Components/SidePanel/SidePanel";

// Lazy load the RolesTable component
const RolesTable = lazy(() => import("../../Components/RolesTable/RolesTable"));
const RolesManagement: React.FC = () => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState<boolean>(false);
  const handleSidePanel = (value: boolean) => {
    setIsSidePanelOpen(value);
  };
  return (
    <div className={style.body}>
      <Suspense
        fallback={
          <div className={style.commonLoaderContainer}>
            <LoadingSpinner variant="loading-spinner" />
          </div>
        }
      >
        <RolesTable handleSidePanel={handleSidePanel} />
        <Panel
          isOpen={isSidePanelOpen}
          togglePanel={() => {
            handleSidePanel(false);
          }}
          alignment="right"
          titleText="Add/Edit Role"
          childComponents={<SidePanel />}
        />
      </Suspense>
    </div>
  );
};

export default RolesManagement;
